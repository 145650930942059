import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import QuizProvider from './context/quiz-context/index.context'
import 'animate.css'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QuizProvider>
      <App />
    </QuizProvider>
  </React.StrictMode>
)
