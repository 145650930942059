import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Footer from '../components/footer'
import { QuizContextType } from '../context/quiz-context/quiz-context.interface'
import { QuizContext } from '../context/quiz-context/index.context'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { CONSTANTS } from '../constants'
import { QuestionListProp } from '../interfaces/quiz-data.interface'

function SingleChoiceQuestionPage({ questionList }: QuestionListProp) {
  const { data, saveData } = useContext(QuizContext) as QuizContextType
  const [canNext, setCanNext] = useState(
    () => !!data.questions[data.currentQuestion]?.answers[0]
  )
  const [inputValue, setInputValue] = useState<string>(
    `${data.questions[data.currentQuestion]?.value}` ?? ''
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    setValue('inputName', false)
    if (data.questions[data.currentQuestion]?.answers) {
      setValue(
        'inputName',
        `${data.questions[data.currentQuestion]?.answers[0]}`
      )
    }
  }, [data.currentQuestion])

  if (!questionList[data.currentQuestion]) return <div></div>
  const { image, width, id, question, answers } =
    questionList[data.currentQuestion]

  const colNumber = Math.round(answers.length / 2)

  const onSubmit = async () => {
    document.body.classList.remove('prevPage')
    document.body.classList.add('nextPage')
    saveData({
      ...data,
      stepIndex: data.stepIndex + 1,
      currentQuestion: data.currentQuestion + 1,
      prevStepIndex: data.stepIndex,
    })
  }

  const handleBack = async () => {
    document.body.classList.remove('nextPage')
    document.body.classList.add('prevPage')
    saveData({
      ...data,
      stepIndex: data.stepIndex - 1,
      currentQuestion: data.currentQuestion > 1 ? data.currentQuestion - 1 : 0,
      prevStepIndex: data.stepIndex,
    })
  }

  const handleSelectAnswer = (e: any) => {
    const answerSelected = e.target.value
    if (
      data.currentQuestion === CONSTANTS.INDEX_QUESTION_HAS_ANSWER_INPUT &&
      answerSelected === '5'
    ) {
      setValue('inputName', false)
      return
    }
    //setInputValue('')
    const itemPoint = answers[parseInt(answerSelected)].point

    const questionUpdated = { ...data.questions }
    questionUpdated[data.currentQuestion] = {
      id,
      answers: [parseInt(answerSelected)],
      point: itemPoint
    }
    setCanNext(true)
    saveData({ ...data, questions: questionUpdated })
    // console.log('Point', itemPoint)
  }
  const handleClickInput = (e: any) => {
    if (!inputValue) {
      setValue('inputName', false)
    } else {
      setValue('inputName', '5')
    }
    const questionUpdated = { ...data.questions }
    questionUpdated[data.currentQuestion] = {
      id,
      answers: [5],
      value: parseInt(inputValue),
    }
    setCanNext(true)
    saveData({ ...data, questions: questionUpdated })
  }
  const handleInputChange = (e: any) => {
    setInputValue(e.value)
    if (e.value !== '') {
      setValue('inputName', '5')
      const questionUpdated = { ...data.questions }
      questionUpdated[data.currentQuestion] = {
        id,
        answers: [5],
        value: parseInt(e.value),
      }
      setCanNext(true)
      saveData({ ...data, questions: questionUpdated })
    } else {
      setValue('inputName', false)
    }
  }

  return (
    <>
      <div className="mx-auto max-w-full" style={{ width: `1024px` }}>
        <form id="mform" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto py-5 px-5 container">
            <div>
              <img src={image} className="mx-auto my-1 sm:my-5 max-w-full" />
            </div>
            <div className="mx-auto max-w-full" style={{ width: `${width}px` }}>
              <div className="text-center text-base sm:text-lg md:text-xl font-helve-bold">
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.replace(
                      '[Name]',
                      data.customerData?.name ?? ''
                    ),
                  }}
                ></div>
              </div>
              <div className={`my-4 sm:my-12 text-center`}>
                <div
                  className={`quiz-content ${data.currentQuestion ===
                    CONSTANTS.INDEX_QUESTION_HAS_ANSWER_INPUT
                    ? `justify-center flex flex-wrap`
                    : `xs grid ${data.currentQuestion === 9
                      ? 'grid-cols-2'
                      : 'grid-cols-1'
                    } md:grid-cols-${colNumber}`
                    }`}
                >
                  {answers.map((answer, index) => (
                    <div
                      key={`k-${index}`}
                      className={`it-ans ${data.currentQuestion ===
                        CONSTANTS.INDEX_QUESTION_HAS_ANSWER_INPUT
                        ? ` max-w-full w-full md:w-1/3`
                        : `w-full`
                        }`}
                    >
                      <input
                        onClick={(e) => handleSelectAnswer(e)}
                        type="radio"
                        {...register(`inputName`, { required: true })}
                        id={`answer-${answer.id}`}
                        value={index}
                        className="hidden peer"
                      />
                      <label
                        htmlFor={`answer-${answer.id}`}
                        className="answer-item inline-flex w-full items-center peer-checked:border-blue-600"
                      >
                        <div
                          style={{ fontSize: '1rem', lineHeight: '1.3rem' }}
                          dangerouslySetInnerHTML={{ __html: answer.content }}
                        ></div>
                      </label>
                    </div>
                  ))}
                  {data.currentQuestion ===
                    CONSTANTS.INDEX_QUESTION_HAS_ANSWER_INPUT && (
                      <div
                        key={`k-5`}
                        className={`it-ans px-2 max-w-full w-full md:w-1/3`}
                      >
                        <label
                          className={`
                    ${data.questions[data.currentQuestion]?.answers[0] === 5
                              ? 'active'
                              : ''
                            }
                    max-w-full 
                    w-full
                    answer-item-input 
                    inline-flex
                    items-center 
                    peer-checked:border-blue-600`}
                        >
                          <input
                            onClick={(e) => handleSelectAnswer(e)}
                            type="radio"
                            {...register(`inputName`, { required: true })}
                            id={`answer-5`}
                            value={5}
                            className="hidden peer"
                          />
                          <NumberFormat
                            thousandSeparator={true}
                            onClick={handleClickInput}
                            onValueChange={handleInputChange}
                            value={inputValue}
                            className={`
                      text-center
                      appearance-none 
                      block
                      bg-gray-200 
                      text-gray-700 
                      border 
                      border-gray-200 
                      rounded 
                      py-2 
                      px-1
                      leading-tight 
                      focus:outline-none 
                      focus:bg-white 
                      focus:border-gray-500
                      `}
                          />
                          <span> triệu đồng</span>
                        </label>
                      </div>
                    )}
                </div>
                {Object.keys(errors).length > 0 ? (
                  <div className="mt-1 sm:mt-4 text-primary text-base text-center font-helve-light">
                    Vui lòng chọn câu trả lời để tiếp tục
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container mx-auto max-w-full" style={{ width: `1024px` }}>
        <Footer
          className="md:relative fixed px-2 md:px-0 bottom-3 left-0 md:flex md:bottom-auto md:left-auto"
          nextText="TIẾP TỤC"
          onPrev={handleBack}
          activeNextButton={canNext}
        />
      </div>
    </>
  )
}

export default SingleChoiceQuestionPage
