import { useContext } from 'react'
import Button from '../components/button'
import Footer from '../components/footer'
import { QuizContext } from '../context/quiz-context/index.context'
import { QuizContextType } from '../context/quiz-context/quiz-context.interface'

function WellComePage() {
  const { data, saveData } = useContext(QuizContext) as QuizContextType
  const handleNextpage = async () => {
    document.body.classList.remove('prevPage')
    document.body.classList.add('nextPage')
    saveData({ ...data, stepIndex: data.stepIndex + 1 })
  }
  return (
    <>
      <div className="mx-auto max-w-full" style={{ width: `1024px` }}>
        <div className="welcome-page mx-auto py-3 px-3 md:py-6 md:px-6 container md:content-center">
          <div className="md:mt-10 flex flex-col md:flex-row content-center items-center">
            <div className="w-full md:px-12 md:w-3/5 mb-8 mt-3">
              <div className="welcome-banner relative ">
                <span
                  className={`lb ${
                    data.prevStepIndex === 0
                      ? 'animate__animated animate__animated animate__fadeInLeft animation-delay-600'
                      : ''
                  }`}
                >
                  GIẢI MÃ
                </span>
                <img
                  className={`image-main mx-auto ${
                    data.prevStepIndex === 0
                      ? 'animate__animated animate__fadeInDown animation-delay-200'
                      : ''
                  }`}
                  src="img/welcome-tt.png"
                  alt="Vina Capital"
                />
                <img
                  className={`image-left absolute ${
                    data.prevStepIndex === 0
                      ? 'animate__animated animate__fadeInLeft animation-delay-400'
                      : ''
                  }`}
                  src="img/welcome-1.png"
                  alt="Vina Capital"
                />
                <img
                  className={`image-right absolute ${
                    data.prevStepIndex === 0
                      ? 'animate__animated animate__fadeInRight animation-delay-600'
                      : ''
                  }`}
                  src="img/welcome-2.png"
                  alt="Vina Capital"
                />
              </div>
            </div>

            <div className="w-full md:px-2 md:w-2/5">
              <div className="content">
                <div
                  style={{ width: '380px' }}
                  className={`text-base sm:text-lg mx-auto max-w-full font-helve-roman text-center description ${
                    data.prevStepIndex === 0
                      ? 'animate__animated animate__fadeInDown'
                      : ''
                  }`}
                >
                  <div>
                    Bạn có tiền nhàn rỗi đang muốn đầu tư nhưng chưa biết rõ bản
                    thân thuộc phong cách đầu tư nào để chọn loại hình đầu tư
                    cho phù hợp.
                  </div>
                  <div className="mt-3">
                    Hãy cùng quỹ mở VinaCapital khám phá
                    <br /> phong cách đầu tư của riêng bạn.
                  </div>
                </div>
                <Footer
        fixedBottom={true}
        className={` md:mt-20 md:relative ${
          data.prevStepIndex === 0
            ? `animate__animated animate__fadeInUp animation-delay-800`
            : ''
        }`}
        prevElement={null}
        nextElement={
          <Button
            onClick={handleNextpage}
            className="font-helve-md w-full"
            variant="primary"
          >
            Bắt Đầu
          </Button>
        }
      />
                {/* <Footer
                  fixedBottom={true}
                  className={`hidden md:inline-block text-center md:mt-20 md:relative ${
                    data.prevStepIndex === 0
                      ? `animate__animated animate__fadeInUp animation-delay-800`
                      : ''
                  }`}
                  prevElement={null}
                  nextElement={
                    <Button
                      id="mbutton"
                      onClick={handleNextpage}
                      className="font-helve-md w-full"
                      variant="primary"
                    >
                      Bắt Đầu
                    </Button>
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer
        fixedBottom={true}
        className={` md:mt-20 md:relative ${
          data.prevStepIndex === 0
            ? `animate__animated animate__fadeInUp animation-delay-800`
            : ''
        }`}
        prevElement={null}
        nextElement={
          <Button
            onClick={handleNextpage}
            className="font-helve-md w-full"
            variant="primary"
          >
            Bắt Đầu
          </Button>
        }
      /> */}
    </>
  )
}

export default WellComePage
