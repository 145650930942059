import React, { memo } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

type Props = {
  prevElement?: React.ReactNode
  nextElement?: React.ReactNode
  prevText?: string
  nextText?: string
  activeNextButton?: boolean
  alignCenter?: boolean
  fixedBottom?: boolean
  loadMore?: boolean
  onNext?: (event: React.MouseEvent) => void
  onPrev?: (event: React.MouseEvent) => void
} & React.ButtonHTMLAttributes<HTMLDivElement>

const Footer = ({
  prevElement,
  nextElement,
  prevText,
  nextText,
  activeNextButton = true,
  alignCenter = false,
  onNext,
  onPrev,
  style,
  className,
  loadMore = false,
  fixedBottom = false,
  ...props
}: Props) => {
  if (prevElement === undefined) {
    prevElement = (
      <button
        type="button"
        className="inline-flex items-center justify-center cursor-pointer"
        onClick={onPrev}
      >
        <ChevronLeftIcon className="h-7 w-7" />
        <span className="font-helve-md">{prevText ?? ``}</span>
      </button>
    )
  }

  if (nextElement === undefined) {
    nextElement = (
      <button
        type="submit"
        form="mform"
        id="mbutton"
        className={`${
          activeNextButton ? 'text-primary' : ''
        } inline-flex items-center justify-center cursor-pointer`}
        onClick={onNext}
      >
        <span className="font-helve-md">{nextText ?? `TIẾP TỤC`}</span>
        <ChevronRightIcon className="h-7 w-7 " />
      </button>
    )
  }
  return (
    <div
      className={clsx(
        `w-full
      align-center
      flex 
      flex-row
      md:mx-auto`,
        {
          'fixed bottom-0 left-0': fixedBottom,
        },
        {
          'justify-between':
            nextElement !== null && prevElement !== null && !alignCenter,
        },
        { 'justify-center': nextElement === null || prevElement === null },
        className
      )}
      style={style}
      {...props}
    >
      {loadMore && <div className="block sm:hidden more-bottom"></div>}
      <>
        {prevElement}
        {nextElement}
      </>
    </div>
  )
}

export default Footer
