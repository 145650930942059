import { useContext, useMemo } from 'react'
import { QuizContext } from './context/quiz-context/index.context'
import { QuizContextType } from './context/quiz-context/quiz-context.interface'
import NamePage from './pages/name-page'
import WellComePage from './pages/wellcome-page'
import SingleChoiceQuestionPage from './pages/single-choice-question-page'
import MultipleChoiceQuestionPage from './pages/multiple-choice-question-page'
import FormPage from './pages/form-page'
import MaoHiem from './pages/mao-hiem'
import { CONSTANTS } from './constants'
import AnToan from './pages/an-toan'
import ThanTrong from './pages/than-trong'
import ManhDan from './pages/manh-dan'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { randomArrayShuffle } from './utils/common'
import questionData from './data.json'
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel';

ReactGA.initialize('UA-203966566-4')
ReactPixel.init('5443739452313602');

function App() {
  const { data } = useContext(QuizContext) as QuizContextType
  let sum = 0
  let firstTime = true
  useEffect(() => {
    if (firstTime) {
      firstTime = false
      ReactGA.pageview(window.location.pathname + window.location.search)

      document.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
          document.getElementById('mbutton')?.click()
        }
      })
    }
  }, [])

  const GTM = 'G-G35DVERD43';

  useEffect(() => {
    if (
      data.stepIndex !== 0 &&
      data.stepIndex !== CONSTANTS.STEP_NAME_PAGE &&
      data.stepIndex !== CONSTANTS.STEP_RESULT &&
      data.stepIndex !== CONSTANTS.STEP_INPUT_FORM
    ) {
      const pageTitle = `Thoải Mái Đầu Tư - Câu hỏi số ${data.currentQuestion + 1}`;
      document.title = pageTitle;

      gtag('event', 'page_view', {
        page_title: pageTitle,
        send_to: GTM,
      });

      ReactPixel.pageView();
    }
  }, [data.currentQuestion, data.stepIndex])

  useEffect(() => {
    if (data.stepIndex === 0) {
      const pageTitle = `Thoải Mái Đầu Tư`;
      document.title = pageTitle;

      gtag('event', 'page_view', {
        page_title: pageTitle,
        send_to: GTM,
      })

      ReactPixel.pageView();
    }

    if (data.stepIndex === CONSTANTS.STEP_RESULT) {
      const pageTitle = 'Thoải Mái Đầu Tư - Kết quả';
      document.title = pageTitle;

      gtag('event', 'page_view', {
        page_title: pageTitle,
        send_to: GTM,
      })

      ReactPixel.trackCustom('CompleteRegistration');
    }

    if (data.stepIndex === CONSTANTS.STEP_NAME_PAGE) {
      const pageTitle = `Thoải Mái Đầu Tư - Trang nhập tên`;
      document.title = pageTitle;

      gtag('event', 'page_view', {
        page_title: pageTitle,
        send_to: GTM,
      })

      ReactPixel.pageView();
    }
    if (data.stepIndex === CONSTANTS.STEP_INPUT_FORM) {
      const pageTitle = `Thoải Mái Đầu Tư - Trang nhập thông tin`;
      document.title = pageTitle;


      gtag('event', 'page_view', {
        page_title: pageTitle,
        send_to: GTM,
      })

      ReactPixel.pageView();
    }

  }, [data.stepIndex])
  const questionList = useMemo(() => {
    return questionData.map((question) => {
      question.answers = randomArrayShuffle(question.answers)
      return question
    })
  }, [])

  const scoreHandle = () => {
    let arr = Object.values(data.questions)
    arr.forEach(item => {
      sum += item.point || 0
    })
  }

  let page
  switch (data.stepIndex) {
    case 0:
      page = <WellComePage />
      break
    case CONSTANTS.STEP_NAME_PAGE:
      page = <NamePage />
      break
    case CONSTANTS.STEP_MULTIPLE_QUESTION:
      page = <MultipleChoiceQuestionPage questionList={questionList} />
      break
    // case CONSTANTS.STEP_INPUT_FORM:
    //   page = <FormPage />
    //   break
    case CONSTANTS.STEP_INPUT_FORM:
      scoreHandle()

      const score = sum ?? 0
      // console.log('ket qua', score)
      if (score <= 17) page = <AnToan />
      else if (score <= 28) page = <ThanTrong />
      else if (score <= 39) page = <ManhDan />
      else page = <MaoHiem />
      break
    default:
      page = <SingleChoiceQuestionPage questionList={questionList} />
  }
  return (
    <>
      <div className="container px-5 mx-auto pt-5 sm:pt-7">
        <img
          className={`logo ${data.stepIndex !== 0 && data.stepIndex !== CONSTANTS.STEP_INPUT_FORM
              ? 'mb-7'
              : ''
            } ${data.stepIndex !== 0 &&
              data.stepIndex !== CONSTANTS.STEP_NAME_PAGE &&
              data.stepIndex !== CONSTANTS.STEP_INPUT_FORM
              ? 'hidden'
              : ''
            } md:block mx-auto`}
          src="img/mobile-logo.png"
          alt="Vina Capital"
        />
        {data.stepIndex > CONSTANTS.STEP_NAME_PAGE &&
          data.stepIndex < CONSTANTS.STEP_INPUT_FORM ? (
          <div
            className="progress-bar max-w-full mx-auto"
            style={{ width: '1024px' }}
          >
            <div
              className="ingress"
              style={{ width: `${data.currentQuestion + 1}0%` }}
            />
          </div>
        ) : null}
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition key={data.stepIndex} timeout={300} classNames={`fade`}>
          {page}
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default App
