import { useContext, useRef, useState } from 'react'
import Footer from '../components/footer'
import { QuizContextType } from '../context/quiz-context/quiz-context.interface'
import { QuizContext } from '../context/quiz-context/index.context'

function NamePage() {
  const { data, saveData } = useContext(QuizContext) as QuizContextType
  const [name, setName] = useState<string>(() => data.customerData?.name || '')
  const [error, setError] = useState<string | null>(null)
  const textInput = useRef<HTMLInputElement>(null)
  const handleSubmitName = async () => {
    if (name === '') {
      textInput.current?.focus()
      setError('Vui lòng nhập tên để tiếp tục')
      return
    }
    // if (/[0-9!`~@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(name)) {
    //   textInput.current?.focus()
    //   setError('Tên không hợp lệ!')
    //   return
    // }
    const newCustomerData = { ...data.customerData }
    newCustomerData.name = name
    document.body.classList.remove('prevPage')
    document.body.classList.add('nextPage')
    saveData({
      ...data,
      customerData: newCustomerData,
      stepIndex: data.stepIndex + 1,
      prevStepIndex: data.stepIndex,
    })
    gtag('event', 'submit_form_name', {
      send_to: 'G-G35DVERD43',
    })
  }
  const handleBack = async () => {
    document.body.classList.remove('nextPage')
    document.body.classList.add('prevPage')
    saveData({
      ...data,
      stepIndex: data.stepIndex - 1,
      prevStepIndex: data.stepIndex,
    })
  }
  return (
    <>
      <div
        className="py-6 px-6 mx-auto container flex justify-center items-center"
        style={{ height: 'calc(100% - 200px)' }}
      >
        <div className={`text-center w-full`}>
          <div>
            <div className="text-2xl md:text-3xl mb-5 font-helve-light">
              Xin chào, tên của bạn là
            </div>
            <div
              className="text-center mx-auto relative z-0 mb-6 max-w-full group"
              style={{ width: '400px' }}
            >
              <input
                ref={textInput}
                autoFocus={true}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                type="email"
                value={name}
                name="floating_email"
                id="floating_email"
                style={{ fontSize: '2.25rem' }}
                className="line-button-primary 
                text-primary 
                font-helve-light text-center 
                block py-2.5 
                px-0 w-full 
                bg-transparent 
                border-0 
                border-b-2"
              />
              {error ? (
                <div className="mt-4 text-primary text-xl text-center font-helve-light">
                  {error}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-w-full" style={{ width: `1024px` }}>
        <Footer
          className="fixed px-2 md:relative md:px-0 bottom-3 left-0 md:flex md:bottom-auto md:left-auto"
          nextText="XÁC NHẬN"
          onPrev={handleBack}
          onNext={handleSubmitName}
        />
      </div>
    </>
  )
}

export default NamePage
