import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { CONSTANTS } from '../../constants'
import { DataType, QuizContextType } from './quiz-context.interface'

// Initiate Context
export const QuizContext = React.createContext<QuizContextType | null>(null)
type Props = {
  children: React.ReactNode
}
const intialData: DataType = {
  prevStepIndex: 0,
  stepIndex: 0,
  currentQuestion: 0,
  customerData: undefined,
  questions: [],
}
const QuizProvider = ({ children }: Props) => {
  const [cookies, setCookie] = useCookies(['quizData'])
  const [data, setData] = useState<DataType>(() => {
    return cookies['quizData'] ?? intialData
  })

  const saveData = (data: DataType) => {
    setData(data)
  }

  useEffect(() => {
    const newData = { ...data }
    if (data.stepIndex === 0) newData.prevStepIndex = 0
    setCookie('quizData', newData, {
      maxAge: 60 * CONSTANTS.EXPIRED_COOKIE_IN_MINUTE,
    })
  }, [data])

  return (
    <QuizContext.Provider value={{ data, saveData }}>
      {children}
    </QuizContext.Provider>
  )
}

export default QuizProvider
