import React, { useContext, useEffect, useRef, useState } from 'react'
import Footer from '../components/footer'
import { QuizContextType } from '../context/quiz-context/quiz-context.interface'
import { QuizContext } from '../context/quiz-context/index.context'
import { useForm } from 'react-hook-form'
import { CONSTANTS } from '../constants'
import { QuestionListProp } from '../interfaces/quiz-data.interface'

function MultipleChoiceQuestionPage({ questionList }: QuestionListProp) {
  const { data, saveData } = useContext(QuizContext) as QuizContextType
  const [canNext, setCanNext] = useState(
    () => !!data.questions[data.currentQuestion]?.answers
  )
  let sumPoint = 0
  const [points, setPoints] = useState([] as any)
  const [sum, setSum] = useState(0)
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()
  const { image, id, question, answers, width } =
    questionList[data.currentQuestion]

  useEffect(() => {
    let defaultAnswers: string[] = []
    if (data.questions[data.currentQuestion]?.answers) {
      defaultAnswers = data.questions[data.currentQuestion]?.answers.map(
        (i) => `${i}`
      )
    }
    setValue('inputName', defaultAnswers)
  }, [data.currentQuestion])

  const onSubmit = async () => {
    document.body.classList.remove('prevPage')
    document.body.classList.add('nextPage')
    saveData({
      ...data,
      stepIndex: data.stepIndex + 1,
      prevStepIndex: data.stepIndex,
      currentQuestion: data.currentQuestion + 1,
    })
  }

  const handleBack = async () => {
    document.body.classList.remove('nextPage')
    document.body.classList.add('prevPage')
    saveData({
      ...data,
      stepIndex: data.stepIndex - 1,
      currentQuestion: data.currentQuestion > 1 ? data.currentQuestion - 1 : 0,
      prevStepIndex: data.stepIndex,
    })
  }

  const handleSumPoint = (arr: any) => {

    arr.forEach((item: any) => {
      sumPoint += item.goal
    })
    // console.log('Tong diem khi check', sumPoint)
    setSum(sumPoint)

    return sumPoint
  }

  const handleSelectAnswer = (e: any) => {
    const answerSelected = parseInt(e.target.value)
    const questionUpdated = { ...data.questions }

    saveData({ ...data, questions: questionUpdated })
    if (answerSelected === CONSTANTS.ID_ANSWER_ITEM_ONLY_ME) {
      setValue('inputName', e.target.value)
      setCanNext(true)
      questionUpdated[data.currentQuestion] = {
        id,
        answers: [answerSelected],
        point: 0
      }
      return
    }

    const formData =
      getValues('inputName') !== false ? getValues('inputName') : []
    let answersSelected = formData.map((i: string) => parseInt(i))
    let goal = answers[answerSelected].point
    if (e.target.checked) {
      answersSelected.push(answerSelected)
      points.push({ goal, answerSelected })
      setPoints(points)
      // console.log('Mang', points)
      handleSumPoint(points)
    } else {
      answersSelected = formData.filter((i: number) => i !== e.target.value)
      const newPoints = points.filter((x: any) => x.answerSelected !== answerSelected)
      handleSumPoint(newPoints)

      // console.log('Tong diem un check', sumPoint)
      // console.log(newPoints)
      setPoints(newPoints)
    }
    const answersSelectedRemovedSpecialAnswer = answersSelected.filter(
      (i: number) => i !== CONSTANTS.ID_ANSWER_ITEM_ONLY_ME
    )

    setCanNext(true)
    setValue(
      'inputName',
      answersSelectedRemovedSpecialAnswer.map((i: number) => `${i}`)
    )
    questionUpdated[data.currentQuestion] = {
      id,
      answers: answersSelectedRemovedSpecialAnswer,
      point: sumPoint

    }
  }
  return (
    <div
      className="multiple-page mx-auto max-w-full"
      style={{ width: `1024px` }}
    >
      <form id="mform" onSubmit={handleSubmit(onSubmit)} className="h-full">
        <div className="mx-auto py-1 px-5 container">
          <div>
            <img src={image} className="mx-auto my-2 max-w-full" />
          </div>
          <div className="mx-auto max-w-full" style={{ width: `${width}px` }}>
            <div className="text-center text-sm sm:text-lg md:text-xl font-helve-bold">
              <div
                dangerouslySetInnerHTML={{
                  __html: question.replace(
                    '[Name]',
                    data.customerData?.name ?? ''
                  ),
                }}
              ></div>
            </div>
            <div className={`mt-3 sm:my-12 text-center`}>
              <div className={`quiz-content grid grid-cols-2 md:grid-cols-5`}>
                {answers.map((answer, index) => (
                  <div
                    key={`k-${index}`}
                    style={{ display: 'inline-block' }}
                    className={`it-ans w-full`}
                  >
                    <input
                      onClick={(e) => handleSelectAnswer(e)}
                      type="checkbox"
                      {...register(`inputName`, { required: true })}
                      id={`answer-${answer.id}`}
                      value={index}
                      className="hidden checkbox"
                    />
                    <label
                      htmlFor={`answer-${answer.id}`}
                      className="font-helve-light text-sm sm:text-base answer-item inline-flex w-full items-center peer-checked"
                    >
                      <div
                        style={{ fontSize: '1rem', lineHeight: '1.3rem' }}
                        dangerouslySetInnerHTML={{ __html: answer.content }}
                      ></div>
                    </label>
                  </div>
                ))}
              </div>
              {Object.keys(errors).length > 0 ? (
                <div className="mt-4 text-primary text-base text-center font-helve-light">
                  Vui lòng chọn câu trả lời để tiếp tục
                </div>
              ) : null}
            </div>
          </div>
          <Footer
            className="md:relative fixed px-2 md:px-0 bottom-3 left-0 md:flex md:bottom-auto md:left-auto"
            nextText="TIẾP TỤC"
            onPrev={handleBack}
            activeNextButton={canNext}
          />
        </div>
      </form>
    </div>
  )
}

export default MultipleChoiceQuestionPage
